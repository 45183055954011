'use strict';
// 19.1.3.6 Object.prototype.toString()
var classof = require(8);
var test = {};
test[require(67)('toStringTag')] = 'z';
if (test + '' != '[object z]') {
  require(51)(Object.prototype, 'toString', function toString() {
    return '[object ' + classof(this) + ']';
  }, true);
}
